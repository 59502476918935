import React from 'react';
import PropTypes from 'prop-types';

import Header from './Header';
import Footer from './Footer';
import CookiesBanner from './CookiesBanner';

import './layout.css';
import '../cssVariables.css';
import '../fonts/fonts.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Layout = ({ children }) => (
  <div className="grid-container">
    <Header />
    <main>{children}</main>
    <Footer />
    <CookiesBanner />
  </div>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
