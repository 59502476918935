import React from 'react';
import cn from 'classnames';
import logo from '../../images/rtk-logo.svg';
import s from './Header.module.css';

const navListItems = [
  { id: 1, name: 'Описание', href: '#description' },
  { id: 2, name: 'Основные функции', href: '#functions' },
  { id: 3, name: 'Статистика', href: '#statistics' },
  { id: 5, name: 'Отзывы', href: '#storeFeedbacks' },
  { id: 6, name: 'План развития', href: '#developmentPlan' },
];

const Header = () => {
  return (
    <header className={s.header}>
      <div className={cn(s.headerInner, 'container')}>
        <img src={logo} className={s.logo} alt="Логотип Ростелекома" />
        <nav>
          <ul className={cn(s.navList, s.tabletHidden)}>
            {navListItems.map((item) => {
              return (
                <li key={item.id} className={s.navListItem}>
                  <a href={item.href} className={s.navListItemLink}>
                    {item.name}
                  </a>
                </li>
              );
            })}
          </ul>
        </nav>
        <a href="#contactInfo" className={cn(s.feedbackLink, s.mobileHidden)}>
          Написать нам
        </a>
      </div>
    </header>
  );
};

export default Header;
