import React from 'react';
import s from './Footer.module.css';

const currentYear = new Date().getFullYear();

const Footer = () => {
  return (
    <div className={s.footer}>
      <div className="container">
        <p className={s.footerText}>
          Продолжая пользоваться сайтом, вы&nbsp;соглашаетесь на&nbsp;обработку файлов cookies
          и&nbsp;других пользовательских данных.
        </p>
        <p className={s.footerText}>© {currentYear} ПАО «Ростелеком». 18+</p>
      </div>
    </div>
  );
};

export default Footer;
