import React, { useState, useEffect } from 'react';
import s from './CookiesBanner.module.css';

const CookiesBanner = () => {
  const [isOpened, setIsOpened] = useState(false);

  const getCookie = (name) => {
    if (typeof document !== 'undefined') {
      const matches = document.cookie.match(
        new RegExp(`(?:^|; )${name.replace(/([\\.$?*|{}\\(\\)\\[\]\\\\/\\+^])/g, '\\$1')}=([^;]*)`),
      );

      return matches ? decodeURIComponent(matches[1]) : undefined;
    }
    return null;
  };

  useEffect(() => {
    if (!getCookie('rtLifeCookie')) {
      setIsOpened(true);
    }
  }, []);

  const setCookie = () => {
    document.cookie = 'rtLifeCookie=true';
    setIsOpened(false);
  };

  if (isOpened) {
    return (
      <div className={s.cookiesBanner}>
        <div className={s.cookiesBannerText}>
          Мы&nbsp;используем файлы cookies для улучшения работы сайта.
          <br className={s.mobileHidden} /> Продолжая работать с&nbsp;сайтом, вы&nbsp;даете согласие
          на&nbsp;их&nbsp;использование.
        </div>
        <button onClick={() => setCookie()} type="button" className={s.cookiesBannerButton}>
          Соглашаюсь
        </button>
      </div>
    );
  }

  return null;
};

export default CookiesBanner;
